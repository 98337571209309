import { Injectable } from '@angular/core';
import { gql, Mutation } from 'apollo-angular';

import { SecuredKeyResponseModel } from '../models/secured-key-response.model';

@Injectable({
  providedIn: 'root',
})
export class EcommerceSearchMutation extends Mutation<SecuredKeyResponseModel, { userKey: string }> {
  override document = gql`
    mutation ($userKey: UUID!) {
      createSecuredKey(userKey: $userKey) {
        securedKey
        hitsPerPage
        delayInMilliseconds
        appId
        localizedIndexes
        allowedRedirectDomains
      }
    }
  `;
}
