import { Injectable, OnDestroy, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Observable, ReplaySubject, Subscription, map, of, switchMap } from 'rxjs';

import { UserService } from '@ppg/auth';
import { CaslAction, CaslSubject } from '@ppg/core/enums';
import { RoleService } from '@ppg/core/permissions';

import { JobsDeliveryAddressGQL, JobsResponse } from '../queries/jobs-delivery-addresses.query';

@Injectable({
  providedIn: 'root',
})
export class CustomerJobsStatesService implements OnDestroy {
  readonly #userService: UserService = inject(UserService);
  readonly #jobsDeliveryAddressGQL: JobsDeliveryAddressGQL = inject(JobsDeliveryAddressGQL);
  readonly #roleService = inject(RoleService);

  private distinctJobsStates$ = new ReplaySubject<string[]>(1);
  private distinctStateCodes: string[] = [];
  private subscription: Subscription | undefined;

  private readonly isPaymentMethodsHidden = computed(
    () =>
      this.#roleService.ability().cannot(CaslAction.READ, CaslSubject.PAYMENT_METHODS) ||
      this.#roleService.ability().cannot(CaslAction.READ, CaslSubject.PRICE),
  );

  public getDistinctJobsStates(): Observable<string[]> {
    return this.distinctJobsStates$.asObservable();
  }

  public readonly isCustomerAllJobsBelongsToPR = toSignal(
    this.isPaymentMethodsHidden()
      ? of(false)
      : this.refreshDistinctJobsStates().pipe(
          map((stateCodes) => (stateCodes ? stateCodes.every((code) => code === 'US-PR') : false)),
        ),
  );

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private refreshDistinctJobsStates(): Observable<string[]> {
    const latestJobsStates$ = this.getAllDistinctJobsStates({ pageSize: 100, pageNumber: 0 }, 0);
    this.subscription = latestJobsStates$.subscribe(this.distinctJobsStates$);
    return latestJobsStates$;
  }

  private getJobsStates(
    paginatedListRequest: { pageSize: number; pageNumber: number },
    customerKey?: string,
  ): Observable<JobsResponse | null> {
    if (!customerKey) {
      return of(null);
    }

    return this.#jobsDeliveryAddressGQL
      .fetch(
        {
          skip: paginatedListRequest.pageNumber * paginatedListRequest.pageSize,
          take: paginatedListRequest.pageSize,
          customerKey: customerKey,
        },
        { fetchPolicy: 'network-only' },
      )
      .pipe(map((response) => response.data as JobsResponse));
  }

  private getAllDistinctJobsStates(
    request: { pageSize: number; pageNumber: number },
    currentPage: number,
  ): Observable<string[]> {
    return this.getJobsStates(request, this.#userService.user()?.customerKey).pipe(
      switchMap((response: JobsResponse | null) => {
        if (!response) {
          return of(this.distinctStateCodes);
        }

        const stateCodes = response.jobs.items.flatMap((job) =>
          job.deliveryAddresses.map((address) => address.stateCode),
        );
        this.distinctStateCodes = Array.from(new Set([...this.distinctStateCodes, ...stateCodes]));

        if (response.jobs.totalCount > (currentPage + 1) * request.pageSize) {
          const newRequest = { pageSize: 100, pageNumber: currentPage + 1 };
          return this.getAllDistinctJobsStates(newRequest, currentPage + 1);
        }
        return of(this.distinctStateCodes);
      }),
    );
  }
}
