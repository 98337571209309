import { BaseItem } from '@algolia/autocomplete-core';
import {
  autocomplete,
  AutocompleteApi,
  AutocompleteOptions,
  AutocompletePlugin,
  AutocompleteSource,
  AutocompleteState,
  getAlgoliaResults,
  HTMLTemplate,
  VNode,
} from '@algolia/autocomplete-js';
import { SearchResponse } from '@algolia/client-search';
import { CommonModule } from '@angular/common';
import {
  afterNextRender,
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  output,
  OutputEmitterRef,
} from '@angular/core';
import { SearchClient } from 'algoliasearch';

import { AlgoliaHit } from '@ppg/algolia/models';
import { FeatureModule } from '@ppg/core/content';
import { AppRoutesService } from '@ppg/core/navigation';
import { ColorUtils } from '@ppg/shared/color/services';
import { appendImageSize } from '@ppg/shared/product-api';

import { DEBOUNCE_TIME, debouncePromise } from './federated-search-debounce';
import {
  COLOR_SOURCE_ID,
  CONTENT_SOURCE_ID,
  LINE_CLAMP_TITLE_AND_BODY_DISPLAYED,
  NO_LINE_CLAMP,
  PRODUCT_SOURCE_ID,
  QUERY_MIN_CHARS_NUMBER,
  STATE_SOURCE_TOTAL_HITS_NUMBER_PREFIX,
} from '../data/consts/federated-search.const';
import { ConfigTypes } from '../data/consts/max-characters-configs.const';
import { EllipsisTextPipe } from '../data/pipes/ellipsis-text.pipe';
import { FederatedSearchService } from '../data/services/federated-search/federated-search.service';

@Component({
  selector: 'ppg-federated-search',
  standalone: true,
  imports: [CommonModule],
  providers: [EllipsisTextPipe],
  templateUrl: './federated-search.component.html',
  styleUrl: './federated-search.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FederatedSearchComponent {
  public readonly searchClickToGlobalSearchPage = output<string>();
  public readonly searchClickToProductPage = output<string>();
  public readonly searchClickToColorsPage = output<string>();

  private readonly colorUtils = inject(ColorUtils);
  private readonly appRoutesService = inject(AppRoutesService);
  private readonly federatedSearchService = inject(FederatedSearchService);
  private readonly ellipsisTextPipe = inject(EllipsisTextPipe);

  private readonly fallbackImageSrc = '/assets/img/federated-search-fallback.png';

  private readonly contentIndex = this.federatedSearchService.contentIndex;
  private readonly productIndex = this.federatedSearchService.productIndex;
  private readonly colorIndex = this.federatedSearchService.colorIndex;
  private readonly cdeUserCookie = this.federatedSearchService.cdeUserCookie;
  private readonly searchClient = this.federatedSearchService.searchClient;
  private readonly textResources = this.federatedSearchService.textResources;
  private readonly contentFilters = this.federatedSearchService.contentFilters;
  private readonly hitsPerPage = this.federatedSearchService.hitsPerPage;
  private readonly colorIdDisplay = this.federatedSearchService.colorIdDisplay;
  private readonly maxCharactersConfiguration = this.federatedSearchService.maxCharactersConfiguration;

  private autocomplete: AutocompleteApi<BaseItem> | null = null;

  private readonly plugins = computed(() => {
    const contentIndex = this.contentIndex();
    const productIndex = this.productIndex();
    const colorIndex = this.colorIndex();

    return [
      ...(contentIndex
        ? [
            this.createPlugin({
              searchClient: this.searchClient,
              sourceId: CONTENT_SOURCE_ID,
              indexName: contentIndex,
              hitsPerPage: this.hitsPerPage(),
              facetFilters: this.contentFilters(),
              header: this.textResources().all,
            }),
          ]
        : []),
      ...(productIndex
        ? [
            this.createPlugin({
              searchClient: this.searchClient,
              sourceId: PRODUCT_SOURCE_ID,
              indexName: productIndex,
              hitsPerPage: this.hitsPerPage(),
              header: this.textResources().products,
            }),
          ]
        : []),
      ...(colorIndex
        ? [
            this.createPlugin({
              searchClient: this.searchClient,
              sourceId: COLOR_SOURCE_ID,
              indexName: colorIndex,
              hitsPerPage: this.hitsPerPage(),
              header: this.textResources().colors,
            }),
          ]
        : []),
    ];
  });

  public readonly autocompleteOptions = computed<AutocompleteOptions<BaseItem>>(() => {
    const { searchPlaceholder, noResultsMessage } = this.textResources();

    const maxWidthStyle = `
      max-width: 540px;
    `;

    const renderStyle = `
      gap: var(--spacing-4XL);
      padding: var(--spacing-2XL) var(--spacing-6XL);
    `;

    const noResultMessageStyle = `
      color: var(--fg-primary);
      font-family: var(--typography-font_family-body);
      font-size: var(--typography-font_size-14);
      font-style: normal;
      font-weight: 400;
      line-height: var(--typography-line_height-20);
    `;

    return {
      container: '#federated-search-autocomplete',
      openOnFocus: true,
      panelPlacement: 'full-width',
      placeholder: searchPlaceholder,
      detachedMediaQuery: 'none',
      classNames: {
        form: 'flex-row-reverse',
        input: 'pl-3',
        clearButton: 'hidden',
        panel: 'border-noround mt-3',
      },
      insights: {
        insightsInitParams: {
          userToken: this.cdeUserCookie,
        },
      },
      onStateChange: ({ prevState, state }) => {
        if (state.query !== prevState.query) {
          this.federatedSearchService.pushLiveSearchResults(state.query);
        }
      },
      onSubmit: ({ state, refresh }) => {
        refresh();
        this.federatedSearchService.pushSubmitSearchResults(state.query);
      },
      plugins: this.plugins(),
      renderNoResults({ html, state, render }, root) {
        if (state.query.length >= QUERY_MIN_CHARS_NUMBER) {
          render(
            html`<div class="flex" style="${renderStyle}">
              <div style="${noResultMessageStyle}" class="w-full" data-test-id="federatedSearch.noResultsForQueryText">
                ${noResultsMessage} '${state.query}'
              </div>
            </div>` as VNode,
            root,
          );
        }
      },
      render({ render, html, elements, state }, root) {
        const { content, colors, products } = elements;

        if (state.query.length >= QUERY_MIN_CHARS_NUMBER) {
          render(
            html`<div>
              <div class="flex flex-column md:flex-row" style="${renderStyle}">
                <div
                  class="${content ? 'w-full' : 'hidden'}"
                  style="${maxWidthStyle}"
                  data-test-id="federatedSearch.content">
                  ${content}
                </div>
                <div
                  class="${colors ? 'w-full' : 'hidden'}"
                  style="${maxWidthStyle}"
                  data-test-id="federatedSearch.colors">
                  ${colors}
                </div>
                <div
                  class="${products ? 'w-full' : 'hidden'}"
                  style="${maxWidthStyle}"
                  data-test-id="federatedSearch.products">
                  ${products}
                </div>
              </div>
            </div>` as VNode,
            root,
          );
        } else {
          render(html`` as VNode, root);
        }
      },
    };
  });

  constructor() {
    afterNextRender(() => {
      this.autocomplete = autocomplete(this.autocompleteOptions());
    });

    effect(() => {
      if (this.autocomplete) {
        this.autocomplete.destroy();
        this.autocomplete = autocomplete(this.autocompleteOptions());
      }
    });
  }

  createPlugin({
    searchClient,
    sourceId,
    indexName,
    hitsPerPage,
    facetFilters,
    header,
  }: {
    searchClient: SearchClient;
    sourceId: 'content' | 'colors' | 'products';
    indexName: string;
    hitsPerPage: number;
    facetFilters?: (string | string[])[];
    header: string;
  }): AutocompletePlugin<AlgoliaHit, unknown> {
    const textResources = this.textResources();
    const searchClickToGlobalSearchPage = this.searchClickToGlobalSearchPage;
    const searchClickToProductPage = this.searchClickToProductPage;
    const searchClickToColorsPage = this.searchClickToColorsPage;

    const lineClampStyle = (lines: number) => `
      display: -webkit-box;
      -webkit-line-clamp: ${lines};
      -webkit-box-orient: vertical;
      overflow: hidden;
      overflow-wrap: anywhere;
    `;

    const headerStyle = `
      color: var(--fg-primary);
      font-family: var(--typography-font_family-body);
      font-size: var(--typography-font_size-14);
      font-style: normal;
      font-weight: 400;
      line-height: var(--typography-line_height-20);
    `;

    const titleStyle = `
      color: var(--fg-primary);
      font-family: var(--typography-font_family-body);
      font-size: var(--typography-font_size-14);
      font-style: normal;
      font-weight: 500;
      line-height: var(--typography-line_height-20);
    `;

    const bodyStyle = `
      color: var(--fg-primary);
      font-family: var(--typography-font_family-body);
      font-size: var(--typography-font_size-12);
      font-style: normal;
      font-weight: 400;
      line-height: var(--typography-line_height-20);
    `;

    const maxCharactersConfig = (configType: ConfigTypes, numberOfColumns: number) =>
      this.maxCharactersConfiguration()
        ?.find((x) => x.configType == configType)
        ?.configs?.find((x) => x.numberOfColumns == numberOfColumns);

    const title = (item: AlgoliaHit) => {
      switch (sourceId) {
        case PRODUCT_SOURCE_ID:
          return item['Name'];

        case COLOR_SOURCE_ID:
          return item['ColorName'];

        default:
          return item.title;
      }
    };

    const itemTitleTemplate = (html: HTMLTemplate, item: AlgoliaHit, state: AutocompleteState<AlgoliaHit>) => {
      const configTypeValue = configType(item, 'titleOnly');
      const config = maxCharactersConfig(configTypeValue, numberOfColumnsDisplayed(state));
      const lineClamp = configTypeValue == 'titleAndBody' ? LINE_CLAMP_TITLE_AND_BODY_DISPLAYED : NO_LINE_CLAMP;
      const titleValue = title(item);

      return titleValue
        ? html`<div style="${titleStyle} ${lineClampStyle(lineClamp)}" data-test-id="federatedSearch.itemTitle">
            ${this.ellipsisTextPipe.transform(title(item), config?.maxCharactersTitle)}
          </div>`
        : null;
    };

    const body = (item: AlgoliaHit) => {
      switch (sourceId) {
        case CONTENT_SOURCE_ID:
          return item.description;

        case COLOR_SOURCE_ID:
          return this.colorIdDisplay() ? item['ColorCode'] : '';

        default:
          return '';
      }
    };

    const itemBodyTemplate = (html: HTMLTemplate, item: AlgoliaHit, state: AutocompleteState<AlgoliaHit>) => {
      const config = maxCharactersConfig(configType(item, 'bodyOnly'), numberOfColumnsDisplayed(state));
      const bodyValue = body(item);

      return bodyValue
        ? html`<div style="${bodyStyle} ${lineClampStyle(NO_LINE_CLAMP)}" data-test-id="federatedSearch.itemBody">
            ${this.ellipsisTextPipe.transform(body(item), config?.maxCharactersBody)}
          </div>`
        : null;
    };

    const configType = (item: AlgoliaHit, originalConfigType: ConfigTypes): ConfigTypes => {
      if (title(item) && body(item)) {
        return 'titleAndBody';
      }

      return originalConfigType;
    };

    const itemImage = (html: HTMLTemplate, item: AlgoliaHit) => {
      switch (sourceId) {
        case PRODUCT_SOURCE_ID:
          return imageWithStandardFallbackBehaviour(html, item['ImageURL']);

        case COLOR_SOURCE_ID:
          return imageWithColorFallbackBehaviour(html, item['ColorImage'], item['RGB']);

        default:
          return imageWithStandardFallbackBehaviour(html, item.image_url);
      }
    };

    const imageWithStandardFallbackBehaviour = (html: HTMLTemplate, itemImageSrc: string | undefined) => {
      if (itemImageSrc) {
        return image(html, itemImageSrc);
      }
      return image(html, this.fallbackImageSrc);
    };

    const imageWithColorFallbackBehaviour = (
      html: HTMLTemplate,
      itemImageSrc: string | undefined,
      rgb: string | undefined,
    ) => {
      if (itemImageSrc) {
        return image(html, itemImageSrc);
      } else if (rgb) {
        return colorBackground(html, rgb);
      }
      return image(html, this.fallbackImageSrc);
    };

    const image = (html: HTMLTemplate, src: string) => {
      const optimizedImg = appendImageSize(src, 'SmallOptimized54');
      return html`<img
        class="h-4rem w-4rem min-w-4rem"
        style="object-fit: cover"
        src="${optimizedImg}"
        onError="${handleOnImageError}" />`;
    };

    const handleOnImageError = (event: Event) => {
      const img = event.target as HTMLImageElement;
      img.src = this.fallbackImageSrc;
    };

    const colorBackground = (html: HTMLTemplate, rgb: string) => {
      const transformedRgb = this.colorUtils.stringToRgb(rgb);
      const backgroundColor = `${this.colorUtils.rgbToHex(transformedRgb)}`;

      return html`<dev class="h-4rem w-4rem min-w-4rem" style="background-color: ${backgroundColor}"></div>`;
    };

    const searchAllHover = (e: MouseEvent) => {
      (e.target as HTMLElement).style.color = 'var(--fg-brand-tertiary-hover)';
    };

    const searchAllEnabled = (e: MouseEvent) => {
      (e.target as HTMLElement).style.color = 'var(--fg-brand-tertiary)';
    };

    const searchButton = (html: HTMLTemplate, setIsOpen: (value: boolean) => void, query: string) => {
      return html` <a
        class="p-button p-button-text gap-2 md:gap-3"
        onclick="${() =>
          this.handleSearchButtonClick(
            sourceId,
            query,
            header,
            setIsOpen,
            searchClickToProductPage,
            searchClickToGlobalSearchPage,
            searchClickToColorsPage,
            this.federatedSearchService,
          )}"
        onmouseover="${searchAllHover}"
        onmouseout="${searchAllEnabled}"
        data-test-id="federatedSearch.searchButton">
        <span class="p-button-label" data-test-id="federatedSearch.searchButtonLabel">${textResources.searchAll}</span>
        <i class="pi pi-chevron-right text-xs" />
      </a>`;
    };

    const debounced = debouncePromise<AutocompleteSource<AlgoliaHit>>((items) => Promise.resolve(items), DEBOUNCE_TIME);
    const getItemDetailsPage = (sourceId: string, item: AlgoliaHit): string | undefined => {
      switch (sourceId) {
        case PRODUCT_SOURCE_ID:
          return `${this.appRoutesService.getPathByAppRouteFeatureModule(FeatureModule.ProductJourneyDetails)}/${item['Name']}/${item.objectID}`;

        case COLOR_SOURCE_ID:
          return `${this.appRoutesService.getPathByAppRouteFeatureModule(FeatureModule.ColorDetails)}/${item['ColorName']}/${item.objectID}`;

        default:
          return item.page_url;
      }
    };

    const numberOfColumnsDisplayed = (state: AutocompleteState<AlgoliaHit>): number => {
      const contentDisplayed = getTotalForSource(state, CONTENT_SOURCE_ID) ? 1 : 0;
      const colorDisplayed = getTotalForSource(state, COLOR_SOURCE_ID) ? 1 : 0;
      const productsDisplayed = getTotalForSource(state, PRODUCT_SOURCE_ID) ? 1 : 0;
      return contentDisplayed + colorDisplayed + productsDisplayed;
    };

    const getTotalForSource = (state: AutocompleteState<AlgoliaHit>, sourceId: string): number =>
      state.context[STATE_SOURCE_TOTAL_HITS_NUMBER_PREFIX + sourceId] as number;

    return {
      getSources({ query, setIsOpen }) {
        return debounced([
          {
            sourceId,
            getItems({ setContext }) {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName,
                    query,
                    params: { hitsPerPage, facetFilters },
                  },
                ],
                transformResponse({ hits, results }) {
                  const result = results[0] as SearchResponse<AlgoliaHit>;
                  setContext({ [STATE_SOURCE_TOTAL_HITS_NUMBER_PREFIX + sourceId]: result.nbHits });
                  return hits;
                },
              });
            },
            templates: {
              header({ html, state }) {
                return html`
                  <div class="flex align-items-center justify-content-between" data-test-id="federatedSearch.header">
                    <div style="${headerStyle}" class="p-2 m-1" data-test-id="federatedSearch.headerLabel">
                      ${header} (${getTotalForSource(state, sourceId)} ${textResources.found})
                    </div>
                    <div class="block md:hidden">${searchButton(html, setIsOpen, query)}</div>
                  </div>
                `;
              },
              item({ item, html, state }) {
                return html`
                  <a
                    class="no-underline"
                    style="color:inherit"
                    href="${getItemDetailsPage(sourceId, item)}"
                    data-test-id="federatedSearch.item">
                    <div class="flex gap-3 m-2">
                      ${itemImage(html, item)}
                      <div class="flex flex-column justify-content-center gap-1">
                        ${itemTitleTemplate(html, item, state)} ${itemBodyTemplate(html, item, state)}
                      </div>
                    </div>
                  </a>
                `;
              },
              footer({ html }) {
                return html` <div class="py-3 hidden md:block" data-test-id="federatedSearch.footer">
                  ${searchButton(html, setIsOpen, query)}
                </div>`;
              },
            },
          },
        ]);
      },
    };
  }

  public handleSearchButtonClick(
    sourceId: 'content' | 'colors' | 'products',
    query: string,
    header: string,
    setIsOpen: (value: boolean) => void,
    searchClickToProductPage: OutputEmitterRef<string>,
    searchClickToGlobalSearchPage: OutputEmitterRef<string>,
    searchClickToColorSearchPage: OutputEmitterRef<string>,
    federatedSearchService: FederatedSearchService,
  ) {
    if (sourceId === PRODUCT_SOURCE_ID) {
      searchClickToProductPage.emit(query);
    }
    if (sourceId === CONTENT_SOURCE_ID) {
      searchClickToGlobalSearchPage.emit(query);
    }
    if (sourceId === COLOR_SOURCE_ID) {
      searchClickToColorSearchPage.emit(query);
    }
    setIsOpen(false);
    federatedSearchService.pushSearchAllResults(query, header);
  }
}
