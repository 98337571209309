import { UrlTarget } from '@ppg/core/enums';
import { NormalizedUrl } from '@ppg/core/services';

export enum HeaderSidebarMenuItemView {
  TITLE,
  DIVIDER,
  BACK_ITEM,
  LINK_ITEM,
  ACTION_ITEM,
  SUB_MENU_ITEM,
  LINK_TITLE,
}

export interface HeaderSidebarCommonMenuItem {
  title: string;
  iconThemeName?: string;
  iconUrl?: string;
}

export type HeaderSidebarLinkMenuItemData = HeaderSidebarCommonMenuItem & NormalizedUrl & { target: UrlTarget };

export interface HeaderSidebarActionMenuItemData extends HeaderSidebarCommonMenuItem {
  subtitle?: string;
  active?: boolean;
  action: () => void;
}

export interface HeaderSidebarBackMenuItemData extends HeaderSidebarCommonMenuItem {}
export interface HeaderSidebarSubMenuItemData extends HeaderSidebarCommonMenuItem {
  children: SidebarMenuItem[];
}

export interface HeaderSidebarLinkMenuItem {
  view: HeaderSidebarMenuItemView.LINK_ITEM;
  data: HeaderSidebarLinkMenuItemData;
}
export interface HeaderSidebarActionMenuItem {
  view: HeaderSidebarMenuItemView.ACTION_ITEM;
  data: HeaderSidebarActionMenuItemData;
}
export interface HeaderSidebarBackMenuItem {
  view: HeaderSidebarMenuItemView.BACK_ITEM;
  data: HeaderSidebarBackMenuItemData;
}
export interface HeaderSidebarSubMenuItem {
  view: HeaderSidebarMenuItemView.SUB_MENU_ITEM;
  data: HeaderSidebarSubMenuItemData;
}

export interface HeaderSidebarDividerMenuItem {
  view: HeaderSidebarMenuItemView.DIVIDER;
}

export interface HeaderSidebarTitleMenuItem {
  view: HeaderSidebarMenuItemView.TITLE;
  data: HeaderSidebarCommonMenuItem;
}

export interface HeaderSidebarLinkTitleMenuItem {
  view: HeaderSidebarMenuItemView.LINK_TITLE;
  data: HeaderSidebarLinkMenuItemData;
}

export type SidebarMenuItem =
  | HeaderSidebarLinkMenuItem
  | HeaderSidebarActionMenuItem
  | HeaderSidebarBackMenuItem
  | HeaderSidebarSubMenuItem
  | HeaderSidebarDividerMenuItem
  | HeaderSidebarTitleMenuItem
  | HeaderSidebarLinkTitleMenuItem;
