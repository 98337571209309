import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ellipsisText', standalone: true })
export class EllipsisTextPipe implements PipeTransform {
  transform(value?: string, maxLength?: number): string {
    if (!value) {
      return '';
    }

    if (!maxLength) {
      return value;
    }

    const isShorterThanMaximumLength = value.length <= maxLength;
    return isShorterThanMaximumLength ? value : `${value.substring(0, maxLength)}...`;
  }
}
