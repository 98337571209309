import { SpecialUtilityItemNavType } from '@ppg/core/content';

import { HeaderSidebarLinkMenuItem, HeaderSidebarSubMenuItem } from '../../../../models/sidebar-menu-items.model';
import { UtilityMenuItemPosition } from '../enums/utility-menu-item-position.enum';

export type UtilityMenuItem = (HeaderSidebarLinkMenuItem | HeaderSidebarSubMenuItem) & {
  type?: SpecialUtilityItemNavType;
};

export class UtilityMenuItems {
  readonly areDefault: boolean;
  readonly position: UtilityMenuItemPosition;
  items: UtilityMenuItem[];

  constructor(areDefault: boolean, position: UtilityMenuItemPosition) {
    this.items = [];
    this.areDefault = areDefault;
    this.position = position;
  }
}

export class UtilityMenuItemsData {
  topLeft: UtilityMenuItems;
  topCenter: UtilityMenuItems;
  topRight: UtilityMenuItems;
  bottomRight: UtilityMenuItems;

  constructor() {
    this.topLeft = new UtilityMenuItems(false, UtilityMenuItemPosition.LEFT);
    this.topCenter = new UtilityMenuItems(false, UtilityMenuItemPosition.CENTER);
    this.topRight = new UtilityMenuItems(true, UtilityMenuItemPosition.RIGHT);
    this.bottomRight = new UtilityMenuItems(false, UtilityMenuItemPosition.RIGHT);
  }

  setTopLeft(items: UtilityMenuItem[]): void {
    this.topLeft.items = items;
  }

  setTopCenter(items: UtilityMenuItem[]): void {
    this.topCenter.items = items;
  }

  setTopRight(items: UtilityMenuItem[]): void {
    this.topRight.items = items;
  }

  setBottomRight(items: UtilityMenuItem[]): void {
    this.bottomRight.items = items;
  }
}
