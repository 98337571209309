import { ProductRepository } from '../data/repository/product-repository';
import { ProductApiServiceAbstract } from '../domain/repository/product-api.service.abstract';
import { ProductRepositoryAbstract } from '../domain/repository/product-repository.abstract';
import { ProductUseCases } from '../domain/use-cases/product-use-cases';

export class ProductFactoryConfig {
  private readonly productRepository: ProductRepositoryAbstract;

  constructor(private apiService: ProductApiServiceAbstract) {
    this.productRepository = new ProductRepository(apiService);
  }

  initProductUseCases(): ProductUseCases {
    return new ProductUseCases(this.productRepository);
  }
}
