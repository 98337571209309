import { computed, inject, Injectable, signal, untracked } from '@angular/core';

import { ConfigManagerService, CurrentSiteState } from '@ppg/configuration';
import { ItemNav } from '@ppg/core/content';

import {
  HeaderSidebarActionMenuItem,
  HeaderSidebarBackMenuItem,
  HeaderSidebarLinkMenuItem,
  HeaderSidebarLinkTitleMenuItem,
  HeaderSidebarMenuItemView,
  HeaderSidebarSubMenuItem,
  SidebarMenuItem,
} from '../../models/sidebar-menu-items.model';
import { HeaderMenuItemUtilsService } from '../../utils/header-menu-item/header-menu-item.utils';
import { HeaderService } from '../header/header.service';

enum SidebarMode {
  CLOSED,
  MAIN_MENU,
  SECONDARY_MENU,
  CHILD_MENU,
}

type SidebarState =
  | { mode: SidebarMode.CLOSED }
  | { mode: SidebarMode.MAIN_MENU }
  | { mode: SidebarMode.SECONDARY_MENU }
  | {
      mode: SidebarMode.CHILD_MENU;
      item: HeaderSidebarSubMenuItem;
      parentTitle: string;
      parentItems: HeaderSidebarSubMenuItem[];
      /**
       * This field is required to identify inside which menu tree user is located,
       * and when it will press back we will render proper root menu and back titles
       */
      rootMode: SidebarMode.MAIN_MENU | SidebarMode.SECONDARY_MENU;
    };

export type ClickableSidebarItem =
  | HeaderSidebarActionMenuItem
  | HeaderSidebarBackMenuItem
  | HeaderSidebarSubMenuItem
  | HeaderSidebarLinkMenuItem
  | HeaderSidebarLinkTitleMenuItem;

@Injectable({ providedIn: 'root' })
export class SidebarDataService {
  private readonly headerService = inject(HeaderService);
  private readonly configManagerService = inject(ConfigManagerService);
  private readonly currentSiteState = inject(CurrentSiteState);
  private readonly headerMenuItemUtilsService = inject(HeaderMenuItemUtilsService);

  private readonly isCurrentSiteRoot = computed(() => !!this.currentSiteState.currentSite()?.isRootSite);
  private readonly rootSiteName = computed(() => this.configManagerService.getRootSite()?.name || '');

  private readonly mainMenuItems = this.headerService.mainMenuItems;
  private readonly subMenuItems = this.headerService.subMenuItems;
  private readonly utilityMenuItems = signal<SidebarMenuItem[]>([]);

  private readonly sidebarState = signal<SidebarState>({ mode: SidebarMode.CLOSED });
  private readonly defaultOpenMenuSate = computed(() =>
    this.isCurrentSiteRoot() ? SidebarMode.MAIN_MENU : SidebarMode.SECONDARY_MENU,
  );

  private readonly mainMenuSidebarItems = computed<SidebarMenuItem[]>(() => {
    return this.headerMenuItemUtilsService.joinGroupsWithDivider(
      this.headerMenuItemUtilsService.mapAPIHeaderMenuItem({ children: this.mainMenuItems() }),
      this.utilityMenuItems(),
    );
  });

  private readonly secondaryMenuSidebarItems = computed<SidebarMenuItem[]>(() =>
    this.headerMenuItemUtilsService.joinGroupsWithDivider(
      [this.headerMenuItemUtilsService.buildBackMenuItem(this.rootSiteName())],
      [
        this.headerMenuItemUtilsService.buildTitleMenuItem(this.currentSiteState.getSiteName()),
        ...this.headerMenuItemUtilsService.mapAPIHeaderMenuItem({ children: this.subMenuItems() }),
      ],
      this.utilityMenuItems(),
    ),
  );

  readonly sidebarLogo = this.headerService.headerLogoSrc;

  readonly sidebarVisible = computed(() => this.sidebarState().mode !== SidebarMode.CLOSED);
  readonly sidebarMenuItems = computed<SidebarMenuItem[]>(() => {
    const sidebarState = this.sidebarState();

    if (sidebarState.mode === SidebarMode.MAIN_MENU) {
      return this.mainMenuSidebarItems();
    }

    if (sidebarState.mode === SidebarMode.SECONDARY_MENU) {
      return this.secondaryMenuSidebarItems();
    }

    if (sidebarState.mode === SidebarMode.CHILD_MENU) {
      const children = sidebarState.item.data.children;
      if (this.utilityMenuItems().includes(sidebarState.item)) {
        // Utility Area should have it own implementation of back button and for such child menus we should not show utility area
        return children;
      }

      return this.headerMenuItemUtilsService.joinGroupsWithDivider(
        [this.headerMenuItemUtilsService.buildBackMenuItem(sidebarState.parentTitle)],
        children,
        this.utilityMenuItems(),
      );
    }

    return [];
  });

  openSidebar(childItem?: ItemNav): void {
    if (!childItem) {
      this.sidebarState.set({ mode: this.defaultOpenMenuSate() });
      return;
    }

    this.sidebarState.set({
      mode: SidebarMode.CHILD_MENU,
      item: {
        view: HeaderSidebarMenuItemView.SUB_MENU_ITEM,
        data: {
          title: childItem.title,
          children: this.headerMenuItemUtilsService.mapAPIHeaderMenuItem(childItem),
        },
      },
      parentTitle: this.currentSiteState.getSiteName(),
      parentItems: [],
      rootMode: this.defaultOpenMenuSate(),
    });
  }

  closeSidebar(): void {
    this.sidebarState.set({ mode: SidebarMode.CLOSED });
  }

  navigateToRootPage(): void {
    if (this.headerService.isUnauthorizedEcommerceUser()) {
      return;
    }
    this.headerService.navigateToRootPage();
    this.closeSidebar();
  }

  setUtilityItems(...items: SidebarMenuItem[][]): void {
    untracked(() => this.utilityMenuItems.set(this.headerMenuItemUtilsService.joinGroupsWithDivider(...items)));
  }

  handleItemClick(item: ClickableSidebarItem): void {
    switch (item.view) {
      case HeaderSidebarMenuItemView.BACK_ITEM:
        this.sidebarState.update((currentState) => this.handleBackItemClick(currentState));
        return;
      case HeaderSidebarMenuItemView.SUB_MENU_ITEM:
        this.sidebarState.update((currentState) => this.handleSubMenuItemClick(item, currentState));
        return;
      case HeaderSidebarMenuItemView.ACTION_ITEM:
        item.data.action();
        return;
      case HeaderSidebarMenuItemView.LINK_TITLE:
      case HeaderSidebarMenuItemView.LINK_ITEM:
        this.closeSidebar();
        return;
    }
  }

  private handleSubMenuItemClick(item: HeaderSidebarSubMenuItem, currentState: SidebarState): SidebarState {
    let parentTitle = this.currentSiteState.getSiteName();
    let rootMode = this.defaultOpenMenuSate();

    if (currentState.mode === SidebarMode.MAIN_MENU && !this.isCurrentSiteRoot()) {
      /**
       * Case when site is not a root one, and by default Secondary menu will be visible.
       * But after user will click back to root site main menu,
       * back button should show root site name instead of current site name + open main menu insteadof secondary menu
       */
      parentTitle = this.rootSiteName();
      rootMode = SidebarMode.MAIN_MENU;
    }
    const parentItems: HeaderSidebarSubMenuItem[] = [];

    if (currentState.mode === SidebarMode.CHILD_MENU) {
      parentItems.push(...currentState.parentItems, currentState.item);
      parentTitle = currentState.item.data.title;
    }

    return { mode: SidebarMode.CHILD_MENU, item, parentItems, parentTitle, rootMode };
  }

  private handleBackItemClick(currentState: SidebarState): SidebarState {
    if (currentState.mode === SidebarMode.MAIN_MENU || currentState.mode === SidebarMode.SECONDARY_MENU) {
      return { mode: SidebarMode.MAIN_MENU };
    }

    if (currentState.mode === SidebarMode.CHILD_MENU) {
      if (!currentState.parentItems.length) {
        return { mode: currentState.rootMode };
      }

      let parentTitle = '';
      const prevLvlItem = currentState.parentItems.pop()!;

      if (!currentState.parentItems.length) {
        parentTitle = this.currentSiteState.getSiteName();
      } else {
        parentTitle = currentState.parentItems[currentState.parentItems.length - 1].data.title;
      }

      return {
        mode: SidebarMode.CHILD_MENU,
        item: prevLvlItem,
        parentItems: currentState.parentItems,
        parentTitle,
        rootMode: currentState.rootMode,
      };
    }

    return currentState;
  }
}
