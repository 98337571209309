import { Injectable } from '@angular/core';
import { gql } from '@apollo/client/core';
import { Query } from 'apollo-angular';

export interface JobsAddressesVariables {
  skip: number;
  take: number;
  customerKey: string;
}

export interface DeliveryAddress {
  stateCode: string;
}

export interface Job {
  deliveryAddresses: DeliveryAddress[];
}

export interface JobCollectionSegment {
  items: Job[];
  totalCount: number;
}

export interface JobsResponse {
  jobs: JobCollectionSegment;
}

@Injectable({
  providedIn: 'root',
})
export class JobsDeliveryAddressGQL extends Query<JobsResponse, JobsAddressesVariables> {
  // eslint-disable-next-line
  override document = gql`
    query ($skip: Int, $take: Int, $customerKey: UUID) {
      jobs(includeInactive: false, skip: $skip, take: $take, customerKey: $customerKey) {
        items {
          deliveryAddresses {
            stateCode
          }
        }
        totalCount
      }
    }
  `;
}
