import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';

import { API_BASE_URL } from '@ppg/core/constants';

import { ProductApiServiceAbstract } from '../../../domain/repository/product-api.service.abstract';
import { Product } from '../../models/product.model';

@Injectable({
  providedIn: 'root',
})
export class ProductApiService implements ProductApiServiceAbstract {
  #baseUrl = inject(API_BASE_URL);
  #http = inject(HttpClient);

  getProducts(): Observable<Product[]> {
    return this.#http.get<Product[]>(`${this.#baseUrl}products/all`).pipe(catchError(() => []));
  }
}
