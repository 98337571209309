import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { HeaderMenuComponent } from '@pds/custom-components/components/header-menu';
import { HeaderMenuItemComponent } from '@pds/custom-components/components/header-menu-item';
import { DividerModule } from 'primeng/divider';

import { HeaderSidebarMenuItemView } from '../../models/sidebar-menu-items.model';
import { SidebarDataService } from '../../services/header-sidebar-data-source/sidebar-data.service';

@Component({
  selector: 'ppg-header-sidebar',
  templateUrl: 'header-sidebar.component.html',
  standalone: true,
  imports: [HeaderMenuComponent, HeaderMenuItemComponent, DividerModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderSidebarComponent {
  protected readonly sidebarDataSource = inject(SidebarDataService);
  protected readonly HeaderSidebarMenuItemView = HeaderSidebarMenuItemView;
}
