import { computed, effect, inject, Injectable, signal } from '@angular/core';

import { AuthService, UserService } from '@ppg/auth';
import { CurrentSiteState } from '@ppg/configuration';
import {
  ContainerNavPosition,
  FeatureModule,
  ItemNav,
  SpecialUtilityItemNavType,
  UtilityItemNav,
} from '@ppg/core/content';
import { CaslAction, CaslSubject, ClientSiteSettingsKeys } from '@ppg/core/enums';
import { AppRoutesService } from '@ppg/core/navigation';
import { RoleService } from '@ppg/core/permissions';
import { FeatureFlagService, GlobalSettings } from '@ppg/features/feature-flag';

import { LanguageMenuDataService } from './language-menu-data.service';
import { UtilityMenuItem, UtilityMenuItemsData } from './models/utility-menu-items.model';
import { HeaderSidebarLinkMenuItem } from '../../../models/sidebar-menu-items.model';
import { HeaderMenuItemUtilsService } from '../../../utils/header-menu-item/header-menu-item.utils';
import { HeaderService } from '../../header/header.service';
import { CustomerJobsStatesService } from '../../payment-methods/services/customer-jobs-states.service';

@Injectable({ providedIn: 'root' })
export class UtilityMenuItemsDataService {
  readonly #currentSiteState = inject(CurrentSiteState);
  readonly #featureFlagService = inject(FeatureFlagService);
  readonly #headerService = inject(HeaderService);
  readonly #headerMenuItemUtilsService = inject(HeaderMenuItemUtilsService);
  readonly #languageMenuDataService = inject(LanguageMenuDataService);
  readonly #userService = inject(UserService);
  readonly #authService = inject(AuthService);
  readonly #roleService = inject(RoleService);
  readonly #appRoutesService = inject(AppRoutesService);
  readonly #customerJobsStatesService = inject(CustomerJobsStatesService);
  readonly #globalSettings = this.#featureFlagService.globalSettings;

  readonly #isPayMyBillDisabled = computed(
    () =>
      !this.#roleService.ability().can(CaslAction.READ, CaslSubject.PAY_MY_BILL) ||
      !this.#currentSiteState.getSiteFlag(ClientSiteSettingsKeys.EcommerceIsEnabled) ||
      this.#headerService.isUnauthorizedEcommerceUser(),
  );
  readonly #isMultiLanguagesIsActive = computed(() =>
    this.#currentSiteState.getSiteFlag(ClientSiteSettingsKeys.MultiLanguagesIsActive),
  );

  readonly #filtredUtilityMenuItems = computed<UtilityItemNav[]>(() => {
    return this.#headerService.utilityMenuItems().reduce((acc, item) => {
      if (this.#shouldFilterUtilityItem(item)) {
        return acc;
      }

      acc.push(item);
      return acc;
    }, [] as UtilityItemNav[]);
  });

  readonly #contactUsUtilityItem = signal<HeaderSidebarLinkMenuItem | null>(null);
  readonly contactUsUtilityItem = this.#contactUsUtilityItem.asReadonly();

  readonly #utilityMenuItems = signal<UtilityMenuItemsData>(new UtilityMenuItemsData());
  readonly utilityMenuItems = this.#utilityMenuItems.asReadonly();

  constructor() {
    effect(
      () => {
        const globalSettings = this.#globalSettings();

        this.#utilityMenuItems.set(this.#getUtilityMenuItems(globalSettings));
        this.#contactUsUtilityItem.set(this.#getContactUsUtilityItem(globalSettings));
      },
      { allowSignalWrites: true },
    );
  }

  #getContactUsUtilityItem(globalSettings: GlobalSettings | undefined) {
    const item = this.#filtredUtilityMenuItems().find(
      (item) => item.utilityNavType === SpecialUtilityItemNavType.CONTACT_US,
    );
    return item ? (this.#mapToUtilitItem(item, globalSettings) as HeaderSidebarLinkMenuItem) : null;
  }

  #getUtilityMenuItems(globalSettings: GlobalSettings | undefined) {
    const utilityMenuItemsData = new UtilityMenuItemsData();
    const allUtilityMenuItems = this.#filtredUtilityMenuItems();

    const topLeft = this.#getPositionedUtilityItems(ContainerNavPosition.TOP_LEFT, allUtilityMenuItems);
    const topCenter = this.#getPositionedUtilityItems(ContainerNavPosition.TOP_CENTER, allUtilityMenuItems);
    const topRight = this.#getPositionedUtilityItems(ContainerNavPosition.TOP_RIGHT, allUtilityMenuItems);
    const bottomRight = this.#getPositionedUtilityItems(ContainerNavPosition.BOTTOM_RIGHT, allUtilityMenuItems);

    const languageMenuItem = this.#isMultiLanguagesIsActive() ? this.#languageMenuDataService.menuItem() : null;

    utilityMenuItemsData.setTopRight(
      topRight.map((item) => this.#mapToUtilitItem(item, globalSettings)).filter((item) => !!item),
    );
    utilityMenuItemsData.setTopLeft(
      topLeft.map((item) => this.#mapToUtilitItem(item, globalSettings)).filter((item) => !!item),
    );
    utilityMenuItemsData.setTopCenter(
      topCenter.map((item) => this.#mapToUtilitItem(item, globalSettings)).filter((item) => !!item),
    );
    utilityMenuItemsData.setBottomRight(
      bottomRight.map((item) => this.#mapToUtilitItem(item, globalSettings)).filter((item) => !!item),
    );

    const unpositionedItems = allUtilityMenuItems
      .filter((item) => !item.position)
      .map((item) => this.#mapToUtilitItem(item, globalSettings));
    Object.values(utilityMenuItemsData)
      .filter((items) => items?.areDefault)
      .forEach((defaultUtilies) => {
        defaultUtilies.items.push(...unpositionedItems);
        if (languageMenuItem) {
          defaultUtilies.items.push(languageMenuItem);
        }
      });

    return utilityMenuItemsData;
  }

  #getPositionedUtilityItems(position: ContainerNavPosition, items: UtilityItemNav[]) {
    return items.filter((item) => item.position === position);
  }

  #mapToUtilitItem(item: UtilityItemNav, globalSettings: GlobalSettings | undefined): UtilityMenuItem | null {
    if (
      (!this.#authService.isAuthenticated() || globalSettings) &&
      item.utilityNavType === SpecialUtilityItemNavType.LOGIN
    ) {
      const profileItem = this.#mapToProfileMenuItem(item, globalSettings);
      return profileItem ? { ...profileItem, type: item.utilityNavType } : null;
    }

    const hasChildren = item.children?.length && item.children?.length > 0;
    let utilityMenuItem: UtilityMenuItem;

    // For now we're using the item.icon.description as the iconThemeName, since we don't have a proper field in ContentAPI yet. It should be changed in the future.
    if (hasChildren) {
      utilityMenuItem = this.#headerMenuItemUtilsService.mapToSubMenuItem(
        item,
        item.title,
        item.icon?.description,
        item.icon?.url,
      );
    } else {
      utilityMenuItem = this.#headerMenuItemUtilsService.mapToLinkMenuItem(
        item,
        item.icon?.description,
        item.icon?.url,
      );
    }

    return { ...utilityMenuItem, type: item.utilityNavType };
  }

  #mapToProfileMenuItem(profileMenuItem: UtilityItemNav, globalSettings: GlobalSettings | undefined) {
    if (!this.#currentSiteState.eCommerceIsAllowLogin()) {
      return null;
    }

    if (!profileMenuItem) {
      return null;
    }

    if (!this.#authService.isAuthenticated()) {
      return this.#headerMenuItemUtilsService.mapToLinkMenuItem(profileMenuItem);
    }

    const user = this.#userService.user();

    if (!user) {
      return null;
    }

    const profileName = `${user.firstName} ${user.lastName}`;

    const filtredProfileMenuItems =
      profileMenuItem.children?.reduce((acc, item) => {
        if (
          !globalSettings ||
          this.#shouldFilterIfUserProfileRestricted(item, globalSettings) ||
          this.#shouldFilterIfPaymentMethodsRestricted(item, globalSettings)
        ) {
          return acc;
        }

        acc.push(item);

        return acc;
      }, [] as ItemNav[]) ?? [];

    profileMenuItem.children = filtredProfileMenuItems;
    profileMenuItem.title = profileName;

    return this.#headerMenuItemUtilsService.mapToSubMenuItem(
      profileMenuItem,
      profileMenuItem.title,
      profileMenuItem.icon?.description,
      profileMenuItem.icon?.url,
    );
  }

  #shouldFilterUtilityItem(item: UtilityItemNav): boolean {
    if (item.utilityNavType === SpecialUtilityItemNavType.PAY_MY_BILL) {
      return this.#isPayMyBillDisabled();
    }

    return false;
  }

  #shouldFilterIfUserProfileRestricted(item: ItemNav, globalSettings: GlobalSettings): boolean {
    if (!this.#isItemNavMatchedFeatureModule(item, FeatureModule.UserProfile)) {
      return false;
    }

    return !globalSettings?.userProfileEnabled;
  }

  #shouldFilterIfPaymentMethodsRestricted(item: ItemNav, globalSettings: GlobalSettings): boolean {
    if (!this.#isItemNavMatchedFeatureModule(item, FeatureModule.PaymentMethods)) {
      return false;
    }

    const user = this.#userService.user();
    if (!user) {
      return true;
    }

    const paymentHidden = this.#roleService.ability().cannot(CaslAction.READ, CaslSubject.PAYMENT_METHODS);
    const pricesHidden = this.#roleService.ability().cannot(CaslAction.READ, CaslSubject.PRICE);

    const userIsFromPuertoRicoAndCardPaymentForPuertoRicoAreDisabled =
      this.#userService.isB2BUser() &&
      globalSettings?.puertoRicoCardPaymentsEnabled === false &&
      (!!this.#customerJobsStatesService.isCustomerAllJobsBelongsToPR() as boolean);

    return (
      globalSettings?.creditCardsEnabled === false ||
      paymentHidden ||
      pricesHidden ||
      userIsFromPuertoRicoAndCardPaymentForPuertoRicoAreDisabled
    );
  }

  #isItemNavMatchedFeatureModule(item: UtilityItemNav, featureModule: FeatureModule): boolean {
    const featureModulePath = this.#appRoutesService.getPathByAppRouteFeatureModule(featureModule);
    return !!featureModulePath && item.url.startsWith(featureModulePath);
  }
}
