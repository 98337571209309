import { computed, inject, Injectable } from '@angular/core';

import { CurrentSiteState } from '@ppg/configuration';
import { LanguagesService } from '@ppg/core/language';
import { SiteLanguage } from '@ppg/core/models';
import { ResourceService } from '@ppg/shared/resource';

import {
  HeaderSidebarActionMenuItem,
  HeaderSidebarMenuItemView,
  HeaderSidebarSubMenuItem,
  SidebarMenuItem,
} from '../../../models/sidebar-menu-items.model';
import { HeaderMenuItemUtilsService } from '../../../utils/header-menu-item/header-menu-item.utils';
import { SidebarDataService } from '../sidebar-data.service';

@Injectable({ providedIn: 'root' })
export class LanguageMenuDataService {
  readonly #currentSiteState = inject(CurrentSiteState);
  readonly #languagesService = inject(LanguagesService);
  readonly #dataSource = inject(SidebarDataService);
  readonly #headerMenuItemUtilsService = inject(HeaderMenuItemUtilsService);
  readonly #resourceService = inject(ResourceService);

  readonly #resources = {
    //TODO: should be replaced by KontetnAI resources
    mainMenu: 'Main Menu',
    regionAndLanguages: 'REGION & LANGUAGES',
  };

  readonly #backMenuGroup = computed<SidebarMenuItem>(() =>
    this.#headerMenuItemUtilsService.buildBackMenuItem(this.#resources.mainMenu),
  );
  readonly #languageTitleMenuItem = computed<SidebarMenuItem>(() =>
    this.#headerMenuItemUtilsService.buildTitleMenuItem(this.#resources.regionAndLanguages),
  );

  readonly #languagesMenuItems = computed(() =>
    this.#currentSiteState
      .sortedSiteLanguages()
      .map((siteLanguage) => this.#mapSiteLanguageToSidebarMenuItem(siteLanguage)),
  );

  readonly menuItem = computed<HeaderSidebarSubMenuItem>(() => ({
    view: HeaderSidebarMenuItemView.SUB_MENU_ITEM,
    data: {
      title: this.#languagesService.language().toUpperCase(),
      iconThemeName: 'web',
      children: [
        this.#backMenuGroup(),
        this.#headerMenuItemUtilsService.buildDividerMenuItem(),
        this.#languageTitleMenuItem(),
        ...this.#languagesMenuItems(),
      ],
    },
  }));

  #switchLanguage(language: SiteLanguage): void {
    this.#languagesService.switchLanguage(language.languageCulture);
    this.#dataSource.closeSidebar();
  }

  #mapSiteLanguageToSidebarMenuItem(siteLanguage: SiteLanguage): HeaderSidebarActionMenuItem {
    return {
      view: HeaderSidebarMenuItemView.ACTION_ITEM,
      data: {
        title: `${siteLanguage.countryName} (${siteLanguage.languageName})`,
        subtitle: `${siteLanguage.nativeCountryName} (${siteLanguage.nativeLanguageName})`,
        active: this.#languagesService.language() === siteLanguage.languageCulture,
        action: () => this.#switchLanguage(siteLanguage),
      },
    };
  }
}
