import { Observable } from 'rxjs';

import { ProductApiServiceAbstract } from '../../domain/repository/product-api.service.abstract';
import { ProductRepositoryAbstract } from '../../domain/repository/product-repository.abstract';
import { Product } from '../models/product.model';

export class ProductRepository implements ProductRepositoryAbstract {
  constructor(private apiService: ProductApiServiceAbstract) {}

  getProducts(): Observable<Product[]> {
    return this.apiService.getProducts();
  }
}
