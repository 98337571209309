import { inject, Injectable } from '@angular/core';

import { ItemNav } from '@ppg/core/content';
import { UrlTarget } from '@ppg/core/enums';
import { UrlResolverService } from '@ppg/core/services';
import { ResourceService } from '@ppg/shared/resource';

import {
  HeaderSidebarBackMenuItem,
  HeaderSidebarDividerMenuItem,
  HeaderSidebarLinkMenuItem,
  HeaderSidebarLinkTitleMenuItem,
  HeaderSidebarMenuItemView,
  HeaderSidebarSubMenuItem,
  HeaderSidebarTitleMenuItem,
  SidebarMenuItem,
} from '../../models/sidebar-menu-items.model';

export type MappableAPIHeaderMenuItem =
  | HeaderSidebarTitleMenuItem
  | HeaderSidebarLinkMenuItem
  | HeaderSidebarSubMenuItem
  | HeaderSidebarLinkTitleMenuItem;

@Injectable({ providedIn: 'root' })
export class HeaderMenuItemUtilsService {
  private readonly urlResolverService = inject(UrlResolverService);
  private readonly resourceService = inject(ResourceService);

  buildDividerMenuItem(): HeaderSidebarDividerMenuItem {
    return { view: HeaderSidebarMenuItemView.DIVIDER };
  }

  joinGroupsWithDivider(...sideBarMenuItems: SidebarMenuItem[][]): SidebarMenuItem[] {
    return sideBarMenuItems.reduce((acc, rec) => {
      if (rec.length > 0) {
        acc.push(...(acc.length > 0 ? [this.buildDividerMenuItem()] : []), ...rec);
      }
      return acc;
    }, [] as SidebarMenuItem[]);
  }

  buildLinkTitleMenuItem(title: string, url: string, target: UrlTarget): HeaderSidebarLinkTitleMenuItem {
    return {
      view: HeaderSidebarMenuItemView.LINK_TITLE,
      data: {
        title,
        target,
        ...this.urlResolverService.normalizeUrl(url),
      },
    };
  }

  buildTitleMenuItem(title: string): HeaderSidebarTitleMenuItem {
    return {
      view: HeaderSidebarMenuItemView.TITLE,
      data: { title },
    };
  }

  buildBackMenuItem(title: string): HeaderSidebarBackMenuItem {
    return {
      view: HeaderSidebarMenuItemView.BACK_ITEM,
      data: { title },
    };
  }

  mapToLinkMenuItem(item: ItemNav, iconThemeName?: string, iconUrl?: string): HeaderSidebarLinkMenuItem {
    return {
      view: HeaderSidebarMenuItemView.LINK_ITEM,
      data: {
        ...this.urlResolverService.normalizeUrl(item.url),
        title: item.title,
        target: item.target,
        iconThemeName: iconThemeName ?? undefined,
        iconUrl: iconUrl ?? item.icon?.url ?? undefined,
      },
    };
  }

  mapToSubMenuItem(
    item: ItemNav,
    subMenuTitle?: string,
    iconThemeName?: string,
    iconUrl?: string,
  ): HeaderSidebarSubMenuItem {
    //TODO: should be replaced by KontetnAI resources
    const mainMenuText = 'Main Menu';

    return {
      view: HeaderSidebarMenuItemView.SUB_MENU_ITEM,
      data: {
        title: item.title,
        iconThemeName: iconThemeName ?? undefined,
        iconUrl: iconUrl ?? item.icon?.url ?? undefined,
        children: [
          this.buildBackMenuItem(mainMenuText),
          this.buildDividerMenuItem(),
          this.buildTitleMenuItem(subMenuTitle ?? item.title),
          ...(item.children ?? []).map((childItem) => this.mapToLinkMenuItem(childItem)),
        ],
      },
    };
  }

  mapAPIHeaderMenuItem(item: Partial<ItemNav>): MappableAPIHeaderMenuItem[] {
    const menuItems: MappableAPIHeaderMenuItem[] = [];

    if (item.title) {
      if (item.url && item.target) {
        menuItems.push(this.buildLinkTitleMenuItem(item.title, item.url, item.target));
      } else {
        menuItems.push(this.buildTitleMenuItem(item.title));
      }
    }

    if (item.link) {
      menuItems.push(this.mapToLinkMenuItem(item.link));
    }

    if (item.children?.length) {
      item.children.forEach((child) => {
        if (child.children?.length) {
          menuItems.push({
            view: HeaderSidebarMenuItemView.SUB_MENU_ITEM,
            data: {
              title: child.title,
              children: this.mapAPIHeaderMenuItem(child),
            },
          });
          return;
        }

        menuItems.push(this.mapToLinkMenuItem(child));
      });
    }

    return menuItems;
  }
}
