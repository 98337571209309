export const DEBOUNCE_TIME = 300;

export function debouncePromise<T>(fn: (params: T[]) => Promise<T[]>, time: number): (args: T[]) => Promise<T[]> {
  let timer: ReturnType<typeof setTimeout> | undefined = undefined;

  return function (args: T[]) {
    if (timer) {
      clearTimeout(timer);
    }

    return new Promise((resolve) => {
      timer = setTimeout(() => resolve(fn(args)), time);
    });
  };
}
