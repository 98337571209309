import { isPlatformServer } from '@angular/common';
import { computed, inject, Injectable, makeStateKey, PLATFORM_ID, TransferState } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { EMPTY, switchMap, tap } from 'rxjs';

import { AuthService } from '@ppg/auth';
import { CurrentSiteState, UrlCreatorService } from '@ppg/configuration';
import { FALLBACK_LOGO_SRC, HEADER_INITIAL_STATE_KEY } from '@ppg/core/constants';
import { Header, ItemNav, ContentService, StaticContentService, UtilityItemNav } from '@ppg/core/content';
import { ClientSiteSettingsKeys, ResourceItem } from '@ppg/core/enums';
import { LanguagesService } from '@ppg/core/language';
import { FeatureRoutePathService } from '@ppg/core/navigation';
import { ResourceService } from '@ppg/shared/resource';

import { HeaderTextResources } from '../../models/text-resource.model';
import { HeaderPermissionsService } from '../header-permissions/header-permissions.service';

@Injectable({ providedIn: 'root' })
export class HeaderService {
  private readonly platformId = inject(PLATFORM_ID);
  private readonly transferState = inject(TransferState);
  private readonly headerStateKey = makeStateKey<Header>(HEADER_INITIAL_STATE_KEY);
  private readonly contentService = inject(ContentService);
  private readonly staticContentService = inject(StaticContentService);
  private readonly languagesService = inject(LanguagesService);
  private readonly urlCreatorService = inject(UrlCreatorService);
  private readonly currentSiteState = inject(CurrentSiteState);
  private readonly router = inject(Router);
  private readonly resourceService = inject(ResourceService);
  private readonly headerPermissionsService = inject(HeaderPermissionsService);
  private readonly authService = inject(AuthService);
  private readonly featureRoutePathService = inject(FeatureRoutePathService);

  readonly isAuthorizedEcommerceUser = computed(
    () => this.currentSiteState.isECommerceEnabled() && this.authService.isAuthenticated(),
  );

  readonly isUnauthorizedEcommerceUser = computed(
    () => this.currentSiteState.isECommerceEnabled() && !this.authService.isAuthenticated(),
  );

  private readonly headerParams = computed(() => ({
    siteId: this.currentSiteState.siteId(),
    language: this.languagesService.language(),
  }));

  private readonly headerInfo$ = toObservable(this.headerParams).pipe(
    switchMap((params) => {
      if (!params.siteId || !params.language) {
        return EMPTY;
      }
      return this.contentService.getHeader(params.siteId, params.language);
    }),
    tap((resp) => {
      if (isPlatformServer(this.platformId)) {
        this.transferState.set(this.headerStateKey, resp);
      }
    }),
  );

  private readonly headerInfo = toSignal<Header | null, Header | null>(this.headerInfo$, {
    initialValue: this.transferState.get(this.headerStateKey, null),
  });

  readonly mainMenuItems = computed<ItemNav[]>(() =>
    this.headerPermissionsService.getPermittedMainMenuItems(this.headerInfo()?.mainMenuItems ?? []),
  );

  readonly subMenuItems = computed<ItemNav[]>(() =>
    this.headerPermissionsService.getPermittedSubMenuItems(this.headerInfo()?.subMenuItems ?? []),
  );

  readonly utilityMenuItems = computed<UtilityItemNav[]>(() =>
    this.headerPermissionsService.getPermittedUtilityMenuItems(this.headerInfo()?.utilityMenuItems ?? []),
  );

  readonly headerNavItems = computed(() =>
    this.currentSiteState.isRootSite() ? this.mainMenuItems() : this.subMenuItems(),
  );

  readonly industryName = computed(() =>
    this.currentSiteState.isRootSite() ? '' : this.currentSiteState.getSiteName(),
  );

  readonly textResources = computed<HeaderTextResources>(() => {
    const placeholderResource = this.currentSiteState.getSiteFlag(ClientSiteSettingsKeys.EcommerceIsEnabled)
      ? ResourceItem.SEARCH_PAGE_ECOMMERCE_PLACEHOLDER
      : ResourceItem.SEARCH_PAGE_PLACEHOLDER;

    return { searchPlaceholder: this.resourceService.getResourceByKey(placeholderResource) };
  });

  readonly headerLogoSrc = computed(
    () =>
      this.staticContentService.getStaticAsset(ClientSiteSettingsKeys.BrandingAssetsHeaderLogo)?.url ??
      FALLBACK_LOGO_SRC ??
      '',
  );

  readonly headerAltText = computed(() =>
    this.currentSiteState.getSiteName()?.toLocaleLowerCase()?.includes('histor')
      ? this.currentSiteState.getSiteName()
      : 'PPG Logo',
  );

  navigateToRootPage(): void {
    if (this.isUnauthorizedEcommerceUser()) {
      return;
    }

    void this.router.navigateByUrl(this.urlCreatorService.createPageUrl(this.languagesService.language()));
  }

  navigateToSiteHubPage(): void {
    const hubPagePath = this.currentSiteState.hubPagePath();

    if (!hubPagePath) {
      return;
    }

    void this.router.navigateByUrl(this.urlCreatorService.createPageUrl(this.languagesService.language(), hubPagePath));
  }

  navigateToGlobalSearchPage(query: string): void {
    const path = this.featureRoutePathService.getGlobalSearchPath(query);

    if (path) {
      void this.router.navigateByUrl(path);
    }
  }

  navigateToProductPage(query: string): void {
    const productJourneyListPath = this.featureRoutePathService.getProductJourneyListPath(query);

    if (productJourneyListPath) {
      void this.router.navigateByUrl(productJourneyListPath);
    }
  }

  navigateToColorPage(query: string): void {
    const path = this.featureRoutePathService.getColorsPath(query);

    if (path) {
      void this.router.navigateByUrl(path);
    }
  }
}
