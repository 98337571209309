import { BaseItem } from '@algolia/autocomplete-core';
import {
  AutocompleteApi,
  AutocompleteComponents,
  HTMLTemplate,
  autocomplete,
  getAlgoliaResults,
} from '@algolia/autocomplete-js';
import { SearchResponse } from '@algolia/client-search';
import { DOCUMENT } from '@angular/common';
import {
  AfterRenderPhase,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Injector,
  NgZone,
  afterNextRender,
  inject,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { InputGroupModule } from 'primeng/inputgroup';
import { filter } from 'rxjs';

import { ProductUseCases } from '@ppg/features/product';

import { AutocompleteConfiguration } from '../data/models/autocomplete-configuration-model';
import { AlgoliaHit, UserDataNonproductItem } from '../data/models/user-data-nonproduct-item.model';
import { ECommerceSearchService } from '../data/services/ecommerce-search/ecommerce-search.service';

@Component({
  selector: 'ppg-ecommerce-search',
  standalone: true,
  imports: [ButtonModule, InputGroupModule],
  templateUrl: './ecommerce-search.component.html',
  styleUrl: './ecommerce-search.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EcommerceSearchComponent {
  private readonly injector = inject(Injector);
  private readonly searchService = inject(ECommerceSearchService);
  private readonly document = inject(DOCUMENT);
  private readonly router = inject(Router);
  private readonly productUseCases = inject(ProductUseCases);
  private readonly ngZone: NgZone = inject(NgZone);
  private destroyRef = inject(DestroyRef);

  private autocomplete: AutocompleteApi<AlgoliaHit> | null = null;

  constructor() {
    toObservable(this.searchService.searchPlaceholderText)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((placeholderText) => {
        this.autocomplete?.update({ placeholder: placeholderText });
      });

    afterNextRender(
      () => {
        this.searchService.$searchConfiguration
          .pipe(filter(Boolean), takeUntilDestroyed(this.destroyRef))
          .subscribe((configurationResponse) => {
            const autocompleteConfig = this.searchService.getAutocompleteConfiguration(configurationResponse);

            const searchClient = autocompleteConfig.searchClient;
            let productCount = 0;

            this.autocomplete?.destroy();

            this.autocomplete = autocomplete<AlgoliaHit>({
              container: '#ecommerce-search-autocomplete',
              openOnFocus: true,
              insights: true,
              onSubmit: ({ state }) => {
                this.ngZone.run(() => {
                  this.router.navigateByUrl(`${autocompleteConfig.productUrl}${encodeURIComponent(state.query)}`);
                });

                this.productUseCases
                  .getProducts(
                    autocompleteConfig.paginatorConfig.first,
                    autocompleteConfig.paginatorConfig.rows,
                    state.query,
                  )
                  .pipe(takeUntilDestroyed(this.destroyRef))
                  .subscribe();
              },
              getSources: ({ query }) => {
                if (!query) {
                  return [
                    {
                      sourceId: 'topSearchSuggestion',
                      getItems() {
                        return getAlgoliaResults({
                          searchClient,
                          queries: [
                            {
                              indexName: autocompleteConfig.indexNameTop,
                              params: {
                                hitsPerPage: autocompleteConfig.hitsPerPage,
                              },
                            },
                          ],
                        });
                      },

                      templates: {
                        item: ({ item, components, html }) => {
                          return this.topKeyWordsSuggestionTemplate(html, components, item, autocompleteConfig);
                        },
                      },
                      onSelect: (event) => {
                        const objectId = event.item[autocompleteConfig.topKeyWordRecord.Name] as string;
                        event.setQuery(objectId);
                        this.onTopKeySuggestionSelect(event.item, autocompleteConfig);
                      },
                    },
                  ];
                }
                return [
                  {
                    sourceId: 'productSuggestion',
                    getItems() {
                      productCount = 0;
                      return getAlgoliaResults<AlgoliaHit>({
                        searchClient,
                        queries: [
                          {
                            indexName: autocompleteConfig.indexName,
                            query,
                            params: {
                              hitsPerPage: autocompleteConfig.hitsPerPage,
                            },
                          },
                        ],
                        transformResponse({ hits, results }) {
                          const result = results[0] as SearchResponse<AlgoliaHit>;
                          let items =
                            result.userData?.map((redirect: UserDataNonproductItem) => {
                              return {
                                hitData: undefined,
                                name: redirect.renderingContent.redirect.Display,
                                url: redirect.renderingContent.redirect.url,
                              };
                            }) || [];
                          if (hits && hits[0]) {
                            items.push(
                              ...hits[0].map((hit: BaseItem) => {
                                return {
                                  hitData: hit,
                                  name: hit['name'] as string,
                                  url: undefined,
                                };
                              }),
                            );
                          }
                          items = items.slice(0, autocompleteConfig.hitsPerPage);
                          productCount = items.length;
                          return items;
                        },
                      });
                    },
                    templates: {
                      item({ item, components, html }) {
                        productCount++;
                        return item['url']
                          ? html`<div class="aa-ItemWrapper">
                              <a class="aa-ItemLink"
                                ><div class="aa-ItemContent"><div class="aa-ItemTitle">${item['name']}</div></div></a
                              >
                            </div>`
                          : html`<div class="aa-ItemWrapper">
                              <div class="aa-ItemContent">
                                <div class="aa-ItemContentBody">
                                  <div class="aa-ItemContentTitle">
                                    ${components.Highlight({
                                      hit: item['hitData'],
                                      attribute: [
                                        autocompleteConfig.productRecord.Name,
                                        autocompleteConfig.productRecord.Language,
                                      ],
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>`;
                      },
                    },
                    onSelect: (event) => {
                      if (event.item['hitData']) {
                        const record = event.item['hitData'] as Record<string, unknown>;
                        const name = record[autocompleteConfig.productRecord.Name] as Record<string, unknown>;
                        const value = name[autocompleteConfig.productRecord.Language] as string;
                        event.setQuery(value);
                        this.ngZone.run(() => {
                          this.router.navigateByUrl(`${autocompleteConfig.productUrl}${encodeURIComponent(value)}`);
                        });
                        this.productUseCases
                          .getProducts(
                            autocompleteConfig.paginatorConfig.first,
                            autocompleteConfig.paginatorConfig.rows,
                            value,
                          )
                          .pipe(takeUntilDestroyed(this.destroyRef))
                          .subscribe();
                      } else {
                        const record = event.item as Record<string, string>;
                        const url: string = record['url'];
                        const isFullUrl = new RegExp('^(http|https)://', 'i').test(url);
                        if (isFullUrl) {
                          window.open(url);
                        } else {
                          this.router.navigate([url]);
                        }
                      }
                    },
                  },
                  {
                    sourceId: 'querySuggestionsWithQuery',
                    getItems() {
                      return getAlgoliaResults({
                        searchClient,
                        queries: [
                          {
                            indexName: autocompleteConfig.indexNameTop,
                            query: '',
                            params: {
                              hitsPerPage: autocompleteConfig.hitsPerPage,
                            },
                          },
                        ],
                        transformResponse({ hits }) {
                          return productCount > 0 ? [] : hits;
                        },
                      });
                    },
                    templates: {
                      item: ({ item, components, html }) => {
                        return this.topKeyWordsSuggestionTemplate(html, components, item, autocompleteConfig);
                      },
                    },
                    onSelect: (event) => {
                      const objectId = event.item[autocompleteConfig.topKeyWordRecord.Name] as string;
                      event.setQuery(objectId);
                      this.onTopKeySuggestionSelect(event.item, autocompleteConfig);
                    },
                  },
                ];
              },
            });

            this.autocomplete?.update({ placeholder: this.searchService.searchPlaceholderText() });
            this.attachEvents();
          });
      },
      { injector: this.injector, phase: AfterRenderPhase.Read },
    );
  }

  doSearch(): void {
    const autocompleteInput = this.document.querySelector('.aa-Input') as HTMLInputElement;
    if (autocompleteInput !== null) {
      if (autocompleteInput.value.length > 0) {
        this.ngZone.run(() => {
          this.searchService.doSearch(autocompleteInput.value);
        });
      }
    }
  }

  private topKeyWordsSuggestionTemplate(
    html: HTMLTemplate,
    components: AutocompleteComponents,
    item: BaseItem,
    autocompleteConfig: AutocompleteConfiguration,
  ) {
    return html`<div class="aa-ItemWrapper">
      <div class="aa-ItemContent">
        <div class="aa-ItemContentBody">
          <div class="aa-ItemContentTitle">
            ${components.Highlight({
              hit: item,
              attribute: [autocompleteConfig.topKeyWordRecord.Name],
            })}
          </div>
        </div>
      </div>
    </div>`;
  }

  private onTopKeySuggestionSelect(item: BaseItem, autocompleteConfig: AutocompleteConfiguration) {
    const objectId = item[autocompleteConfig.topKeyWordRecord.Name] as string;
    this.ngZone.run(() => {
      this.router.navigateByUrl(`${autocompleteConfig.productUrl}${encodeURIComponent(objectId)}`);
    });
    this.productUseCases
      .getProducts(autocompleteConfig.paginatorConfig.first, autocompleteConfig.paginatorConfig.rows, objectId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  private attachEvents() {
    const autocompleteInput = this.document.querySelector('.aa-Input')!;
    if (autocompleteInput) {
      autocompleteInput.addEventListener('keyup', () => this.searchService.regenerateToken());
      autocompleteInput.addEventListener('focus', () => this.searchService.regenerateToken());
    }
  }
}
