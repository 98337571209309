import { inject, Injectable } from '@angular/core';

import { AuthService } from '@ppg/auth';
import { ItemNav } from '@ppg/core/content';
import { RoleService } from '@ppg/core/permissions';

@Injectable({ providedIn: 'root' })
export class HeaderPermissionsService {
  private readonly authService = inject(AuthService);
  private readonly roleService = inject(RoleService);

  getPermittedMainMenuItems(items: ItemNav[] = []): ItemNav[] {
    return this.getPermittedNavItems(items);
  }

  getPermittedSubMenuItems(items: ItemNav[] = []): ItemNav[] {
    return this.getPermittedNavItems(items);
  }

  getPermittedUtilityMenuItems(items: ItemNav[] = []): ItemNav[] {
    return this.getPermittedNavItems(items);
  }

  /**
   * Applies permissioning logic to the provided navigation items:
   * 1. Check if `isAuthenticationRequired`
   *    - if not, permit item
   *    - if yes, continue checks
   * 2. Check if user `isAuthenticated`
   *    - if not, block item
   *    - if yes, continue checks
   * 3. Check if any `permissions` are required
   *    - if not, permit item
   *    - if yes, continue checks
   * 4. Check that user's actual permissions match the required
   *    - if not, block item
   *    - if yes, permit item
   * 5. Repeat recursively for `children`
   */
  private getPermittedNavItems(items: ItemNav[] = []): ItemNav[] {
    return items
      .filter(({ isAuthenticationRequired, permissions }) => {
        if (!isAuthenticationRequired) {
          return true;
        }

        if (!this.authService.isAuthenticated()) {
          return false;
        }

        if (!permissions?.length) {
          return true;
        }

        return this.roleService.checkPermissions(permissions);
      })
      .map((item) => ({
        ...item,
        ...(item.children && { children: this.getPermittedNavItems(item.children) }),
      }));
  }
}
