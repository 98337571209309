<pds-header-menu
  [sidebarVisible]="sidebarDataSource.sidebarVisible()"
  [logoSrc]="sidebarDataSource.sidebarLogo() || ''"
  [logoAltText]="'PPG Logo'"
  (onHeaderMenuHide)="sidebarDataSource.closeSidebar()"
  (onLogoClick)="sidebarDataSource.navigateToRootPage()">
  @for (menuItem of sidebarDataSource.sidebarMenuItems(); track $index) {
    @switch (menuItem.view) {
      @case (HeaderSidebarMenuItemView.DIVIDER) {
        <p-divider />
      }
      @case (HeaderSidebarMenuItemView.TITLE) {
        <pds-header-menu-item
          [isTitleItem]="true"
          [itemLabel]="menuItem.data.title"
          [icon]="menuItem.data.iconThemeName || ''" />
      }
      @case (HeaderSidebarMenuItemView.LINK_TITLE) {
        <pds-header-menu-item
          [isTitleItemClickable]="true"
          [itemLabel]="menuItem.data.title"
          [itemTarget]="menuItem.data.target"
          [itemUrl]="$any(menuItem.data).url"
          [itemRouterLink]="$any(menuItem.data).routerLink"
          [queryParams]="$any(menuItem.data).queryParams"
          [icon]="menuItem.data.iconThemeName || ''"
          (click)="sidebarDataSource.handleItemClick(menuItem)" />
      }
      @case (HeaderSidebarMenuItemView.BACK_ITEM) {
        <pds-header-menu-item
          [isPreviousItem]="true"
          [isClickEventItem]="true"
          [itemLabel]="menuItem.data.title"
          [icon]="menuItem.data.iconThemeName || ''"
          (click)="sidebarDataSource.handleItemClick(menuItem)" />
      }
      @case (HeaderSidebarMenuItemView.SUB_MENU_ITEM) {
        <pds-header-menu-item
          [showChildItemIcon]="true"
          [isClickEventItem]="true"
          [itemLabel]="menuItem.data.title"
          [icon]="menuItem.data.iconThemeName || ''"
          (click)="sidebarDataSource.handleItemClick(menuItem)" />
      }
      @case (HeaderSidebarMenuItemView.LINK_ITEM) {
        <pds-header-menu-item
          [itemTarget]="menuItem.data.target"
          [itemUrl]="$any(menuItem.data).url"
          [itemRouterLink]="$any(menuItem.data).routerLink"
          [queryParams]="$any(menuItem.data).queryParams"
          [itemLabel]="menuItem.data.title"
          [icon]="menuItem.data.iconThemeName || ''"
          [showExternalLinkIcon]="menuItem.data.target !== '_self'"
          (click)="sidebarDataSource.handleItemClick(menuItem)" />
      }
      @case (HeaderSidebarMenuItemView.ACTION_ITEM) {
        <pds-header-menu-item
          [selected]="!!menuItem.data.active"
          [itemSubLabel]="menuItem.data.subtitle || ''"
          [itemLabel]="menuItem.data.title"
          [isClickEventItem]="true"
          [isMultilineItem]="true"
          [icon]="menuItem.data.iconThemeName || ''"
          (click)="sidebarDataSource.handleItemClick(menuItem)" />
      }
    }
  }
</pds-header-menu>
