export type ConfigTypes = 'titleOnly' | 'bodyOnly' | 'titleAndBody';
export const MAX_CHARACTERS_CONFIG_DESKTOP = [
  {
    configType: 'bodyOnly' as ConfigTypes,
    configs: [
      {
        numberOfColumns: 3,
        maxCharactersBody: 102,
        maxCharactersTitle: 0,
      },
      {
        numberOfColumns: 2,
        maxCharactersBody: 158,
        maxCharactersTitle: 0,
      },
      {
        numberOfColumns: 1,
        maxCharactersBody: 158,
        maxCharactersTitle: 0,
      },
    ],
  },
  {
    configType: 'titleOnly' as ConfigTypes,
    configs: [
      {
        numberOfColumns: 3,
        maxCharactersTitle: 104,
        maxCharactersBody: 0,
      },
      {
        numberOfColumns: 2,
        maxCharactersTitle: 200,
        maxCharactersBody: 0,
      },
      {
        numberOfColumns: 1,
        maxCharactersTitle: 200,
        maxCharactersBody: 0,
      },
    ],
  },
  {
    configType: 'titleAndBody' as ConfigTypes,
    configs: [
      {
        numberOfColumns: 3,
        maxCharactersTitle: 80,
        maxCharactersBody: 50,
      },
      {
        numberOfColumns: 2,
        maxCharactersTitle: 134,
        maxCharactersBody: 85,
      },
      {
        numberOfColumns: 1,
        maxCharactersTitle: 134,
        maxCharactersBody: 85,
      },
    ],
  },
];

export const MAX_CHARACTERS_CONFIG_TABLET = [
  {
    configType: 'bodyOnly' as ConfigTypes,
    configs: [
      {
        numberOfColumns: 3,
        maxCharactersBody: 43,
        maxCharactersTitle: 0,
      },
      {
        numberOfColumns: 2,
        maxCharactersBody: 91,
        maxCharactersTitle: 0,
      },
      {
        numberOfColumns: 1,
        maxCharactersBody: 155,
        maxCharactersTitle: 0,
      },
    ],
  },
  {
    configType: 'titleOnly' as ConfigTypes,
    configs: [
      {
        numberOfColumns: 3,
        maxCharactersTitle: 27,
        maxCharactersBody: 0,
      },
      {
        numberOfColumns: 2,
        maxCharactersTitle: 104,
        maxCharactersBody: 0,
      },
      {
        numberOfColumns: 1,
        maxCharactersTitle: 200,
        maxCharactersBody: 0,
      },
    ],
  },
  {
    configType: 'titleAndBody' as ConfigTypes,
    configs: [
      {
        numberOfColumns: 3,
        maxCharactersTitle: 48,
        maxCharactersBody: 22,
      },
      {
        numberOfColumns: 2,
        maxCharactersTitle: 68,
        maxCharactersBody: 45,
      },
      {
        numberOfColumns: 1,
        maxCharactersTitle: 48,
        maxCharactersBody: 22,
      },
    ],
  },
];

export const MAX_CHARACTERS_CONFIG_MOBILE = [
  {
    configType: 'bodyOnly' as ConfigTypes,
    configs: [
      {
        numberOfColumns: 3,
        maxCharactersBody: 66,
        maxCharactersTitle: 0,
      },
      {
        numberOfColumns: 2,
        maxCharactersBody: 66,
        maxCharactersTitle: 0,
      },
      {
        numberOfColumns: 1,
        maxCharactersBody: 66,
        maxCharactersTitle: 0,
      },
    ],
  },
  {
    configType: 'titleOnly' as ConfigTypes,
    configs: [
      {
        numberOfColumns: 3,
        maxCharactersTitle: 70,
        maxCharactersBody: 0,
      },
      {
        numberOfColumns: 2,
        maxCharactersTitle: 70,
        maxCharactersBody: 0,
      },
      {
        numberOfColumns: 1,
        maxCharactersTitle: 70,
        maxCharactersBody: 0,
      },
    ],
  },
  {
    configType: 'titleAndBody' as ConfigTypes,
    configs: [
      {
        numberOfColumns: 3,
        maxCharactersTitle: 53,
        maxCharactersBody: 34,
      },
      {
        numberOfColumns: 2,
        maxCharactersTitle: 53,
        maxCharactersBody: 34,
      },
      {
        numberOfColumns: 1,
        maxCharactersTitle: 53,
        maxCharactersBody: 34,
      },
    ],
  },
];
