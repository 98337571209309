import { HttpErrorResponse } from '@angular/common/http';
import { signal, WritableSignal } from '@angular/core';
import { catchError, delay, finalize, map, Observable, take, tap, throwError } from 'rxjs';

import { Product } from '../../data/models/product.model';
import { ProductsDataViewModel } from '../../data/models/products-data-view.model';
import { ProductRepositoryAbstract } from '../repository/product-repository.abstract';

export class ProductUseCases {
  readonly initialState: ProductsDataViewModel = {
    products: [],
    rows: 0,
    first: 0,
    totalRecords: 0,
  };
  isLoading: WritableSignal<boolean> = signal(false);
  productsDataView: WritableSignal<ProductsDataViewModel> = signal({ ...this.initialState });

  constructor(private productRepository: ProductRepositoryAbstract) {}

  getProducts(skip: number, takeCount: number, searchTerm: string = ''): Observable<ProductsDataViewModel> {
    this.setLoading(true);

    return this.productRepository.getProducts().pipe(
      take(1),
      delay(500),
      map((products: Product[]): ProductsDataViewModel => {
        let filteredProducts: Product[] = [...products];

        if (searchTerm) {
          const searchTermLowerCase: string = searchTerm.toLowerCase();

          filteredProducts = products.filter((product: Product) =>
            product.shortName.toLowerCase().includes(searchTermLowerCase),
          );
        }

        return {
          products: filteredProducts.slice(skip, skip + takeCount),
          first: skip,
          rows: takeCount,
          totalRecords: filteredProducts.length,
        };
      }),
      tap((productsDataView: ProductsDataViewModel): void => this.setProducts(productsDataView)),
      catchError((err: HttpErrorResponse) => {
        this.setProducts({ ...this.initialState });
        return throwError(() => err);
      }),
      finalize(() => this.setLoading(false)),
    );
  }

  setLoading(isLoading: boolean): void {
    this.isLoading.set(isLoading);
  }

  setProducts(productsDataView: ProductsDataViewModel): void {
    this.productsDataView.set(productsDataView);
  }
}
