import { Injectable, computed, effect, inject } from '@angular/core';
import { Router } from '@angular/router';
import algoliasearch from 'algoliasearch/lite';
import { MutationResult } from 'apollo-angular';
import { BehaviorSubject, Observable } from 'rxjs';

import { AuthService, UserService } from '@ppg/auth';
import { PAGINATOR_CONFIG_DEFAULT } from '@ppg/core/constants';
import { FeatureModule } from '@ppg/core/content';
import { ResourceItem } from '@ppg/core/enums';
import { LanguagesService } from '@ppg/core/language';
import { PaginatorConfigModel } from '@ppg/core/models';
import { AppRoutesService } from '@ppg/core/navigation';
import { BaseLocalStorageService } from '@ppg/core/services';
import { ResourceService } from '@ppg/shared/resource';

import { AutocompleteConfiguration } from '../../models/autocomplete-configuration-model';
import { SecuredKeyResponseModel } from '../../models/secured-key-response.model';
import { EcommerceSearchMutation } from '../../queries/ecommerce-search.mutation';

@Injectable({ providedIn: 'root' })
export class ECommerceSearchService extends BaseLocalStorageService<SecuredKeyResponseModel> {
  protected override localStorageKey = 'eCommerceSearchStorageInfo';
  private readonly router = inject(Router);
  private readonly authUseCases = inject(AuthService);
  private readonly userService = inject(UserService);
  private readonly languagesService = inject(LanguagesService);
  private readonly resourceService = inject(ResourceService);
  private readonly ecommerceSearchMutation = inject(EcommerceSearchMutation);
  private readonly appRoutesService = inject(AppRoutesService);

  public $searchConfiguration = new BehaviorSubject<SecuredKeyResponseModel | null>(null);

  private readonly PRODUCT_SUGGESTIONS_INDEX_KEY = 'LocalizedProductSuggestionIndexNameTemplate';
  private readonly TOP_KEYWORD_SUGGESTIONS_INDEX_KEY = 'LocalizedTopKeyWordSuggestionIndexNameTemplate';
  private readonly paginatorConfig: PaginatorConfigModel = PAGINATOR_CONFIG_DEFAULT;
  private readonly productsUrl = computed(() =>
    this.appRoutesService.getPathByAppRouteFeatureModule(FeatureModule.ProductsList),
  );

  public readonly searchPlaceholderText = computed<string>(() => {
    return this.resourceService.getResourceByKey(ResourceItem.SEARCH_PAGE_ECOMMERCE_PLACEHOLDER);
  });

  constructor() {
    super();
    const config = this.getFromLocalStorage();

    effect(() => {
      const isAuthorizedUser = this.authUseCases.isAuthenticated();
      if (isAuthorizedUser) {
        this.$searchConfiguration.next(config);
      }
    });

    effect(() => {
      const userKey = this.userService.userKey();
      if (userKey) {
        this.$searchConfiguration.next(config);
        if (config == null || !this.isTokenValid(userKey, config)) {
          this.generateSecuredKey(userKey);
        }
      }
    });
  }

  isTokenValid(currentUserKey: string, config: SecuredKeyResponseModel | null) {
    const USER_TOKEN_GUID_LENGTH = 36;
    const configuration = config?.createSecuredKey?.securedKey;
    if (configuration == null || configuration === '') {
      return false;
    }

    const utf8Decoder = new TextDecoder('utf-8');
    const decodeValue = utf8Decoder.decode(Uint8Array.from(atob(configuration), (c) => c.charCodeAt(0)));

    const validTillUnixTimeStamp = decodeValue.substring(
      decodeValue.indexOf('validUntil=') + 'validUntil='.length,
      decodeValue.indexOf('&userToken='),
    );

    const userKey = decodeValue.substring(
      decodeValue.indexOf('&userToken=') + '&userToken='.length,
      decodeValue.indexOf('&userToken=') + '&userToken='.length + USER_TOKEN_GUID_LENGTH,
    );

    const validTillUnixTimeStampMiliSeconds = Number(validTillUnixTimeStamp) * 1000;
    const validTillInUTC = new Date(validTillUnixTimeStampMiliSeconds);
    const currentDate = new Date();
    return !(currentDate.toISOString() > validTillInUTC.toISOString() || currentUserKey !== userKey);
  }

  generateSecuredKey(userKey: string) {
    this.createSecuredKey(userKey).subscribe((securedKey) => {
      if (securedKey.data) {
        this.$searchConfiguration.next(securedKey.data as SecuredKeyResponseModel);
        this.setInLocalStorage(securedKey.data as SecuredKeyResponseModel);
      } else {
        this.$searchConfiguration.next({} as SecuredKeyResponseModel);
      }
    });
  }

  getAutocompleteConfiguration(config: SecuredKeyResponseModel): AutocompleteConfiguration {
    const language = this.languagesService.language();
    return {
      searchApiKey: config.createSecuredKey.securedKey,
      indexName: this.replaceWithLanguage(config, this.PRODUCT_SUGGESTIONS_INDEX_KEY),
      indexNameTop: this.replaceWithLanguage(config, this.TOP_KEYWORD_SUGGESTIONS_INDEX_KEY),
      hitsPerPage: config.createSecuredKey.hitsPerPage,
      searchClient: algoliasearch(this.getApplicationID(config), config.createSecuredKey.securedKey),
      language: language,
      productUrl: `${this.productsUrl()}?search=`,
      paginatorConfig: this.paginatorConfig,
      productRecord: {
        Name: 'Name',
        Language: language,
      },
      topKeyWordRecord: {
        Name: 'objectID',
      },
    };
  }

  doSearch(query: string) {
    this.router.navigateByUrl(`${this.productsUrl()}?search=${encodeURIComponent(query)}`);
  }

  regenerateToken() {
    const userKey = this.userService.userKey();
    const configuration = this.getFromLocalStorage();
    if (userKey && !this.isTokenValid(userKey, configuration)) {
      this.generateSecuredKey(userKey);
    }
  }

  private createSecuredKey(userKey: string): Observable<MutationResult<SecuredKeyResponseModel>> {
    return this.ecommerceSearchMutation.mutate({ userKey });
  }

  private replaceWithLanguage(searchConfiguration: SecuredKeyResponseModel, indexKey: string): string {
    const index = searchConfiguration.createSecuredKey.localizedIndexes.find((x) => x.key === indexKey);
    const activeLanguage = this.languagesService.language().replace('-', '_').toLowerCase();
    return index?.value.replace('{0}', activeLanguage) ?? '';
  }

  private getApplicationID(searchConfiguration: SecuredKeyResponseModel) {
    const utf8Decoder = new TextDecoder('utf-8');
    return utf8Decoder.decode(
      Uint8Array.from(atob(searchConfiguration.createSecuredKey.appId), (c) => c.charCodeAt(0)),
    );
  }
}
