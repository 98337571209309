import { DOCUMENT, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, inject, input } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderComponent as PdsHeaderComponent } from '@pds/custom-components/components/header';
import { HeaderBarComponent } from '@pds/custom-components/components/header-bar';
import { SearchComponent } from '@pds/custom-components/components/search';
import { UtilityBarComponent } from '@pds/custom-components/components/utility-bar';
import { MenuItem } from 'primeng/api';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputTextModule } from 'primeng/inputtext';

import { CurrentSiteState } from '@ppg/configuration';
import { ItemNav, SpecialUtilityItemNavType } from '@ppg/core/content';
import { ClientSiteSettingsKeys, UrlTarget } from '@ppg/core/enums';
import { RouterUrl, UrlResolverService } from '@ppg/core/services';
import { EcommerceSearchComponent } from '@ppg/features/ecommerce-search';
import { FederatedSearchComponent } from '@ppg/features/federated-search';
import { ImageComponent } from '@ppg/shared/components';

import {
  HeaderSidebarLinkMenuItem,
  HeaderSidebarLinkMenuItemData,
  HeaderSidebarMenuItemView,
} from '../../models/sidebar-menu-items.model';
import { HeaderService } from '../../services/header/header.service';
import { SidebarDataService } from '../../services/header-sidebar-data-source/sidebar-data.service';
import { UtilityMenuItem } from '../../services/header-sidebar-data-source/utility-section/models/utility-menu-items.model';
import { UtilityMenuItemsDataService } from '../../services/header-sidebar-data-source/utility-section/utility-menu-items-data.service';

@Component({
  selector: 'ppg-header',
  standalone: true,
  imports: [
    DividerModule,
    InputTextModule,
    ButtonModule,
    InputGroupModule,
    HeaderBarComponent,
    PdsHeaderComponent,
    SearchComponent,
    UtilityBarComponent,
    EcommerceSearchComponent,
    FederatedSearchComponent,
    NgClass,
    ImageComponent,
    BadgeModule,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  private readonly currentSiteState = inject(CurrentSiteState);
  private readonly router = inject(Router);
  private readonly document = inject(DOCUMENT);
  private readonly sidebarDataService = inject(SidebarDataService);
  private readonly utilityMenuItemsDataService = inject(UtilityMenuItemsDataService);
  protected readonly headerService = inject(HeaderService);
  protected readonly urlResolverService = inject(UrlResolverService);

  protected readonly cartType = SpecialUtilityItemNavType.CART;

  readonly cartItemsTotalQuantity = input<number>(0);

  protected readonly cartBadge = computed(() => {
    const cartItemsTotalQuantity = this.cartItemsTotalQuantity();

    if (cartItemsTotalQuantity > 0) {
      return `${cartItemsTotalQuantity}`;
    }

    return '';
  });

  protected readonly isAlgoliaActive = computed(() =>
    this.currentSiteState.getSiteFlag(ClientSiteSettingsKeys.AlgoliaIsActive),
  );

  protected readonly isFederatedSearchEnabled = computed(() =>
    this.currentSiteState.getSiteFlag(ClientSiteSettingsKeys.FederatedSearchIsEnabled),
  );

  protected isEComSearchAvailable = this.headerService.isAuthorizedEcommerceUser;
  protected isContentSearchAvailable = computed(
    () => !this.currentSiteState.isECommerceEnabled() && this.isAlgoliaActive(),
  );
  protected isSearchAvailable = computed(
    () => this.isEComSearchAvailable() || this.isContentSearchAvailable() || this.isFederatedSearchEnabled(),
  );

  readonly menuItems = computed(() => this.headerService.headerNavItems().map(this.mapToMenuItem.bind(this)));
  readonly utilityMenuItems = computed(() => this.utilityMenuItemsDataService.utilityMenuItems());

  constructor() {
    effect(() => {
      const utilities = [
        ...this.utilityMenuItems().topLeft.items,
        ...this.utilityMenuItems().topCenter.items,
        ...this.utilityMenuItems().topRight.items,
      ];

      const accountMenuUtilities = this.utilityMenuItems().bottomRight.items;

      this.sidebarDataService.setUtilityItems(accountMenuUtilities, utilities);
    });
  }

  openSideBar(item?: ItemNav): void {
    this.sidebarDataService.openSidebar(item);
  }

  utilityMenuItemClick(item: UtilityMenuItem): void {
    if (item.view === HeaderSidebarMenuItemView.SUB_MENU_ITEM) {
      this.sidebarDataService.handleItemClick(item);
      return;
    }

    if (!this.isHeaderSidebarLinkMenuItem(item)) {
      return;
    }

    if (!this.isMenuItemDataWithRouterUrl(item.data)) {
      this.document.defaultView?.open(item.data.url, item.data.target);
      return;
    }

    if (item.data.target === UrlTarget.SELF) {
      void this.router.navigateByUrl(item.data.routerLink);
    } else {
      this.document.defaultView?.open(item.data.routerLink, '_blank');
    }
  }

  protected isMenuItemDataWithRouterUrl(
    data: HeaderSidebarLinkMenuItemData,
  ): data is HeaderSidebarLinkMenuItemData & RouterUrl {
    return (data as RouterUrl).routerLink !== undefined;
  }

  protected isHeaderSidebarLinkMenuItem(item: Omit<UtilityMenuItem, 'isDefault'>): item is HeaderSidebarLinkMenuItem {
    return item.view === HeaderSidebarMenuItemView.LINK_ITEM;
  }

  protected getHeaderSidebarLinkMenuItemUrl(item: HeaderSidebarLinkMenuItem): string {
    return this.isMenuItemDataWithRouterUrl(item.data) ? item.data.routerLink : item.data.url;
  }

  private mapToMenuItem(item: ItemNav): MenuItem {
    const menuItemBase: MenuItem = { label: item.title, codename: item.codename };

    if (item.children?.length) {
      return {
        ...menuItemBase,
        command: () => this.openSideBar(item),
      };
    }

    return {
      ...menuItemBase,
      ...this.urlResolverService.normalizeUrl(item.url),
      target: item.target,
    };
  }
}
