<pds-header>
  <pds-utility-bar>
    @if (utilityMenuItems(); as utilityMenuItems) {
      <div class="w-full flex flex-row justify-content-between">
        @for (
          positionedItems of [utilityMenuItems.topLeft, utilityMenuItems.topCenter, utilityMenuItems.topRight];
          track $index
        ) {
          <div
            [attr.data-test-id]="'pdsHeader.utilityBar.' + positionedItems.position"
            [class]="'max-w-max justify-content-' + positionedItems.position"
            [ngClass]="{
              hidden: !positionedItems.areDefault,
              'md:inline-flex': !positionedItems.areDefault,
              'inline-flex': positionedItems.areDefault,
              'md:mx-0': positionedItems.areDefault,
              'ml-auto': positionedItems.areDefault,
              'mr-0': positionedItems.areDefault,
            }">
            @for (item of positionedItems.items; track $index; let last = $last) {
              @if (isHeaderSidebarLinkMenuItem(item)) {
                <a [href]="getHeaderSidebarLinkMenuItemUrl(item)" [target]="item.data.target">
                  <p-button [text]="true">{{ item?.data?.title }}</p-button>
                </a>
              } @else {
                <p-button [text]="true" (onClick)="utilityMenuItemClick(item)">{{ item?.data?.title }}</p-button>
              }

              @if (!last) {
                <p-divider class="my-1" layout="vertical"></p-divider>
              }
            }
          </div>
        }
      </div>
    }
  </pds-utility-bar>
  <pds-header-bar
    [logoSrc]="headerService.headerLogoSrc() || ''"
    [logoAltText]="headerService.headerAltText() || 'PPG Logo'"
    [industryName]="headerService.industryName()"
    [showProfile]="false"
    [showFavorites]="false"
    [showCart]="false"
    [menuItems]="menuItems()"
    (onLogoClick)="headerService.navigateToRootPage()"
    (menuToggle)="openSideBar()"
    (onIndustryNameClick)="headerService.navigateToSiteHubPage()">
    @if (isSearchAvailable()) {
      <pds-search search>
        @if (isFederatedSearchEnabled()) {
          <ppg-federated-search
            (searchClickToGlobalSearchPage)="headerService.navigateToGlobalSearchPage($event)"
            (searchClickToProductPage)="headerService.navigateToProductPage($event)"
            (searchClickToColorsPage)="headerService.navigateToColorPage($event)" />
        } @else if (isEComSearchAvailable()) {
          <ppg-ecommerce-search />
        } @else if (isContentSearchAvailable()) {
          <p-inputGroup class="search-input-group relative ml-auto">
            <input
              #searchInput
              id="header-search-input"
              type="text"
              pInputText
              [placeholder]="headerService.textResources().searchPlaceholder"
              (keyup.enter)="headerService.navigateToGlobalSearchPage(searchInput.value)" />

            @if (searchInput.value) {
              <button
                id="header-search-clear-button"
                class="clear-search-button absolute z-1 cursor-pointer"
                (click)="searchInput.value = ''">
                <svg class="pds-icon">
                  <use href="assets/pds-icons.svg#close-circle-outline" />
                </svg>
              </button>
            }

            <button
              type="button"
              pButton
              (click)="headerService.navigateToGlobalSearchPage(searchInput.value)"
              class="h-full">
              <i class="search-icon">
                <svg class="pds-icon">
                  <use href="assets/pds-icons.svg#magnify" />
                </svg>
              </i>
            </button>
          </p-inputGroup>
        }
      </pds-search>
    }
    @if (utilityMenuItems()?.bottomRight; as utilitiesBottomRight) {
      <div iconsSlot>
        @for (item of utilitiesBottomRight.items; track $index) {
          <p-button
            styleClass="inline-flex flex-wrap align-content-center justify-content-center gap-1"
            [text]="true"
            severity="secondary"
            (onClick)="utilityMenuItemClick(item!)">
            @if (item?.data?.iconUrl) {
              <div>
                <ppg-image [url]="item.data.iconUrl!" width="auto" height="auto" [isResponsive]="false"></ppg-image>
                @if (item?.type === cartType && cartBadge()) {
                  <p-badge class="cart-badge m-0" styleClass="m-0" [value]="cartBadge()"></p-badge>
                }
              </div>
            }
            <span [ngClass]="{ 'hidden lg:block': item?.data?.iconUrl, block: !item?.data?.iconUrl }">{{
              item?.data?.title
            }}</span></p-button
          >
        }
      </div>
    }
  </pds-header-bar>
</pds-header>
